import axios from "axios";

// const BASE_URL = "http://10.60.62.163:8000/webreact";
const BASE_URL = "https://stepcounterdevapi.kavinsoft.in/webreact";
// const BASE_URL = "https://stepcounterdemoapi.kavinsoft.in/webreact";

const api = {
  login: async (userid, password, additionalData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/weblogin`,
        {
          userid,
          password,
          ...additionalData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Login error:", error.message);
      throw error;
    }
  },
  getCards: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard_count`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getNewUsers: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/month_wise_register`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getDayWiseAchievement: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/day_wise_achivevement`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getTopPerformers: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/top_performer`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getActiveMembers: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/active_member`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getDailyData: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/get_steps_count_by_day`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getWeeklyData: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/get_steps_count_by_week`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
  getMonthlyData: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/get_steps_count_by_month`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching dashboard data", error.message);
      throw error;
    }
  },
}
export default api;