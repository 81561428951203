import React, { useEffect, useCallback } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";
import Login from "./components/auth/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import Main from "./components/layout/Main";

export default function App() {
  const hasSessionId = !!sessionStorage.getItem("sessionId");
  const navigate = useNavigate();
  const logout = useCallback(() => {
    sessionStorage.removeItem("sessionId");
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    if (!hasSessionId) {
      navigate("/");
      return;
    }

    // Timer to logout after 1 hour
    const logoutTimer = setTimeout(() => {
      logout();
    }, 3600000); // 1 hour in milliseconds

    // Reset timer on user activity
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      setTimeout(() => {
        logout();
      }, 3600000);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [hasSessionId, navigate, logout]);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/main/*" element={<Main />} />
      </Routes>
    </div>
  );
}
