// // App.js
// import React, { useRef, useState } from "react";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import api from "../api/api";
// const Login = () => {
//   const [loading, setLoading] = useState(false);
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const navigate = useNavigate();

//   const toast = useRef(null);
//   const formik = useFormik({
//     initialValues: {
//       userid: "",
//       password: "",
//       rememberMe: false,
//     },
//     validationSchema: Yup.object({
//       userid: Yup.string().required("User name is required"),
//       password: Yup.string().required("Password is required"),
//     }),
//     onSubmit: async (values) => {
//       try {
//         setLoading(true);
//         const additionalData = {
//           synceddatetime: "2023-01-24 11:57:34",
//           FormCode: "201",
//           ApiKey: "kavin",
//           AppTypeNo: "3",
//           AppVersion: "1.0.0",
//           DbVersion: "10.4.1",
//           DbSource: "W",
//         };
//         const response = await api.login(
//           values.userid,
//           values.password,
//           additionalData
//         );
//         // console.log("res", response);
//         if (response && response.status === "1") {
//           const sessionID = uuidv4();
//           sessionStorage.setItem("sessionId", sessionID);
//           navigate();
//         } else {
//           console.log("api res", response.responsemessage);
//           const errorMessage = response
//             ? response.responsemessage || "Invalid username or password"
//             : "Unexpected API response format";
//           toast.current.show({
//             severity: "error",
//             summary: "Login Failed",
//             detail: errorMessage,
//           });
//         }
//       } catch (error) {
//         console.error("Error:", error);
//         toast.current.show({
//           severity: "error",
//           summary: "Login Failed",
//           detail: "An unexpected error occurred. Please try again.",
//         });
//       } finally {
//         setLoading(false);
//       }
//     },
//   });
//   return (
//     <div className="login-page">
//       <div className="content-container">
//         <div className="welcome-section">
//           <div className="logo-container">
//             <img src="/mhs-logo.png" alt="mH+ Stride Logo" className="logo" />
//           </div>
//           <h2>Welcome!..</h2>
//           <p className="subtext">
//             Your health journey made simple. Track your steps, set new
//             challenges, and watch your progress grow. Every step counts—let's
//             stride towards your health together!
//           </p>
//         </div>
//         <div className="login-form-section">
//           <h3 className="let-start">LET'S GET YOU STARTED</h3>
//           <h2 className="login-acc">Login to your account</h2>
//           <div className="login-form">
//             <div className="p-float-label">
//               <InputText id="email" type="text" className="emailInput" />
//               <label htmlFor="email">Email</label>
//             </div>

//             <div className="p-float-label password-field">
//               <InputText
//                 id="password"
//                 type={passwordVisible ? "text" : "password"}
//                 style={{
//                   paddingRight: "40px",
//                   borderRadius: "8px",
//                 }}
//               />
//               <label htmlFor="password">Password</label>
//               <i
//                 className={`pi ${passwordVisible ? "pi-eye-slash" : "pi-eye"}`}
//                 style={{
//                   position: "absolute",
//                   top: "50%",
//                   right: "10px",
//                   transform: "translateY(-50%)",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => setPasswordVisible((prev) => !prev)}
//               ></i>
//             </div>

//             <Button
//               label="LOGIN"
//               className="get-started-button"
//               type="submit"
//               onClick={}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Background */}
//       <div className="background-overlay">
//         <img
//           src="/MHS-Login-bg.png"
//           alt="Background"
//           className="background-image"
//         />
//       </div>
//     </div>
//   );
// };

// export default Login;
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

import { v4 as uuidv4 } from "uuid";
import api from "../api/api";
import once from './../../../node_modules/lodash-es/once';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const toast = useRef(null);
  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: Yup.object({
      userid: Yup.string().required("User name is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const additionalData = {
          synceddatetime: "2023-01-24 11:57:34",
          FormCode: "201",
          ApiKey: "kavin",
          AppTypeNo: "3",
          AppVersion: "1.0.0",
          DbVersion: "10.4.1",
          DbSource: "W",
        };
        const response = await api.login(
          values.userid,
          values.password,
          additionalData
        );

        if (response && response.status === "1") {
          const sessionID = uuidv4();
          sessionStorage.setItem(
            "UserID",
            response.loged_in_details[0].fld_email
          );
          sessionStorage.setItem(
            "Password",
            response.loged_in_details[0].fld_password
          );
          sessionStorage.setItem(
            "Email",
            response.loged_in_details[0].fld_email
          );
          sessionStorage.setItem("sessionId", sessionID);
          // console.log("res", response);
          navigate("/main/dashboard"); //
        } else {
          const errorMessage =
            response?.responsemessage || "Invalid username or password";
          toast.current.show({
            severity: "error",
            summary: "Login Failed",
            detail: errorMessage,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "An unexpected error occurred. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="login-page">
      <Toast ref={toast} />
      <div className="content-container">
        <div className="welcome-section">
          <div className="logo-container">
            <img src="/mhs-logo.png" alt="mH+ Stride Logo" className="logo" />
          </div>
          <h2>Welcome!..</h2>
          <p className="subtext">
            Your health journey made simple. Track your steps, set new
            challenges, and watch your progress grow. Every step counts—let's
            stride towards your health together!
          </p>
        </div>
        <div className="login-form-section">
          <h3 className="let-start">LET'S GET YOU STARTED</h3>
          <h2 className="login-acc">Login to your account</h2>
          <form className="login-form" 
          onSubmit={formik.handleSubmit}
          >
            <div className="p-float-label">
              <InputText
                id="userid"
                name="userid"
                value={formik.values.userid}
                onChange={formik.handleChange}
                className={`emailInput ${
                  formik.errors.userid && formik.touched.userid
                    ? "p-invalid"
                    : ""
                }`}
              />
              <label htmlFor="userid">User Name</label>
              {formik.errors.userid && formik.touched.userid && (
                <small className="p-error">{formik.errors.userid}</small>
              )}
            </div>

            <div className="p-float-label password-field">
              <InputText
                id="password"
                name="password"
                type={passwordVisible ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                className={`passwordInput ${
                  formik.errors.password && formik.touched.password
                    ? "p-invalid"
                    : ""
                }`}
                style={{
                  paddingRight: "40px",
                  borderRadius: "8px",
                }}
              />
              <label htmlFor="password">Password</label>
              <i
                className={`pi ${passwordVisible ? "pi-eye-slash" : "pi-eye"}`}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setPasswordVisible((prev) => !prev)}
              ></i>
              {formik.errors.password && formik.touched.password && (
                <small className="p-error">{formik.errors.password}</small>
              )}
            </div>

            <Button
              label={loading ? "Logging in..." : "LOGIN"}
              className="get-started-button"
              type="submit"
              disabled={loading}
              // onClick={()=>navigate("/main/dashboard")}
            />
          </form>
        </div>
      </div>
      <div className="background-overlay">
        <img
          src="/MHS-Login-bg.png"
          alt="Background"
          className="background-image"
        />
      </div>
    </div>
  );
};

export default Login;
