import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../api/api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Knob } from "primereact/knob";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
export default function Members() {
  const [activeMembers, setActiveMembers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateNW, setSelectedDateNW] = useState(null);
  const [workingDailyStepsData, setWorkingDailyStepsData] = useState(0);
  const [workingDailyTimeData, setWorkingDailyTimeData] = useState(0);
  const [nonWorkingDailyStepsData, setNonWorkingDailyStepsData] = useState(0);
  const [nonWorkingDailyTimeData, setNonWorkingDailyTimeData] = useState(0);
  const [workingWeeklyData, setWorkingWeeklyData] = useState([]);
  const [nonWorkingWeeklyData, setNonWorkingWeeklyData] = useState([]);
  const [workingMonthlyData, setWorkingMonthlyData] = useState([]);
  const [nonWorkingMonthlyData, setNonWorkingMonthlyData] = useState([]);
  const targetSteps = 4000;
  const UserId = sessionStorage.getItem("UserID");
  const Password = sessionStorage.getItem("Password");
  const getActiveMembersData = async () => {
    try {
      setLoading(true);
      const requestData = {
        userid: UserId,
        password: Password,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getActiveMembers(requestData);
      if (result && result.status === 200) {
        const dataWithSerialNumbers = result.data.dashboard_count.map((item, index) => ({
          ...item,
          slno: index + 1,
        }));
        setActiveMembers(dataWithSerialNumbers);
        // setActiveMembers(result.data.dashboard_count);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };

  const getDailyWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "1",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getDailyData(requestData);
      // console.log("result", result.data.dashboard_count);
      if (result && result.status === 200) {
        // console.log("result daily", result.data.loged_in_details[0]);
        const details = result.data.loged_in_details[0];
        setWorkingDailyStepsData(details.counts);
        setWorkingDailyTimeData(details.timing);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getDailyNonWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "2",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getDailyData(requestData);
      // console.log("result", result);
      if (result && result.status === 200) {
        const details = result.data.loged_in_details[0];
        setNonWorkingDailyStepsData(details.counts);
        setNonWorkingDailyTimeData(details.timing);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getWeeklyWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "1",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getWeeklyData(requestData);
      // console.log("result", result);
      if (result && result.status === 200) {
        const getWeekData = result.data.loged_in_details.map((entry) => ({
          Day: entry.day_of_week,
          Steps: entry.day_wise_step_count,
          Time: entry.fld_time,
        }));
        // console.log("week", getWeekData);
        setWorkingWeeklyData(getWeekData);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };

  const getWeeklyNonWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "2",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getWeeklyData(requestData);
      if (result && result.status === 200) {
        const getWeekData = result.data.loged_in_details.map((entry) => ({
          Day: entry.day_of_week,
          Steps: entry.day_wise_step_count,
          Time: entry.fld_time,
        }));
        // console.log("week", getWeekData);
        setNonWorkingWeeklyData(getWeekData);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };

  const getMonthlyWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "1",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getMonthlyData(requestData);
      if (result && result.status === 200) {
        const responseData = result.data.loged_in_details;
        // console.log("monthly data res",result)
        // console.log("monthly data", responseData);
        setWorkingMonthlyData(responseData);
      } else {
        console.error("Unexpected API response format:", result);
        setWorkingMonthlyData([]);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
      setWorkingMonthlyData([]);
    } finally {
      setLoading(false);
    }
  };
  const getBackgroundColor = (date) => {
    if (!Array.isArray(workingMonthlyData)) {
      return ""; // If data is not an array or undefined, return default
    }
    const formattedDate = format(date, "yyyy-MM-dd"); // Convert to "YYYY-MM-DD" format
    const dayData = workingMonthlyData.find((d) => d.date === formattedDate);

    if (dayData) {
      if (dayData.day_wise_step_count >= targetSteps) {
        return "#00C851"; // Target achieved
      } else if (dayData.day_wise_step_count >= targetSteps / 2) {
        return "#FFB71B"; // Half of the target achieved
      } else {
        return "#FF4444"; // Below half the target
      }
    }

    return ""; // Default color if no data
  };

  // Custom render for day cells
  const highlightWithColors = (date) => {
    const backgroundColor = getBackgroundColor(date);
    return (
      <div
        style={{
          backgroundColor,
          color: backgroundColor ? "white" : "black",
          borderRadius: "50%",
          width: "2rem",
          height: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {date.getDate()}
      </div>
    );
  };

  const getMonthlyNonWorkingStepsData = async (email) => {
    try {
      setLoading(true);
      const requestData = {
        fld_email: email,
        synceddatetime: "10-12-2024 10:15:32",
        working_status: "2",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "1",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getMonthlyData(requestData);

      if (result && result.status === 200) {
        const responseData = result.data.loged_in_details;
        setNonWorkingMonthlyData(responseData);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  const getBackgroundColorNW = (date) => {
    if (!Array.isArray(nonWorkingMonthlyData)) {
      return ""; // If data is not an array or undefined, return default
    }
    const formattedDate = format(date, "yyyy-MM-dd"); // Convert to "YYYY-MM-DD" format
    const dayData = nonWorkingMonthlyData.find((d) => d.date === formattedDate);

    if (dayData) {
      if (dayData.day_wise_step_count >= targetSteps) {
        return "#00C851"; // Target achieved
      } else if (dayData.day_wise_step_count >= targetSteps / 2) {
        return "#FFB71B"; // Half of the target achieved
      } else {
        return "#FF4444"; // Below half the target
      }
    }

    return ""; // Default color if no data
  };

  // Custom render for day cells
  const highlightWithColorsNW = (date) => {
    const backgroundColor = getBackgroundColorNW(date);
    return (
      <div
        style={{
          backgroundColor,
          color: backgroundColor ? "white" : "black",
          borderRadius: "50%",
          width: "2rem",
          height: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {date.getDate()}
      </div>
    );
  };

  useEffect(() => {
    getActiveMembersData();
  }, []);

  const viewButtonTemplate = (rowData) => {
    return (
      <Button
        label="Dashboard"
        className="viewButton"
        icon="pi pi-external-link"
        onClick={() => {
          setSelectedMember(rowData);
          setVisible(true);
          console.log("Selected Member:", rowData.fld_name);
          getDailyWorkingStepsData(rowData.fld_email);
          getDailyNonWorkingStepsData(rowData.fld_email);
          getWeeklyWorkingStepsData(rowData.fld_email);
          getWeeklyNonWorkingStepsData(rowData.fld_email);
          getMonthlyWorkingStepsData(rowData.fld_email);
          getMonthlyNonWorkingStepsData(rowData.fld_email);
        }}
      />
    );
  };
  const shortenedWeeklyWorkingData = workingWeeklyData.map((item) => ({
    ...item,
    Day: item.Day.slice(0, 3),
  }));
  const shortenedWeeklyNonWorkingData = nonWorkingWeeklyData.map((item) => ({
    ...item,
    Day: item.Day.slice(0, 3),
  }));
  return (
    <div className="container">
      <Dialog
        // header={
        //   selectedMember
        //     ? `${selectedMember.fld_name}'s Dashboard`
        //     : "User Dashboard"
        // }
        header={
          selectedMember ? (
            <span style={{ paddingLeft: "10px" }}>
              <span
                style={{
                  color: "#7265e2",
                  fontWeight: "bold",
                  paddingRight: "5px",
                }}
              >
                {selectedMember.fld_name}'s
              </span>
              Dashboard
            </span>
          ) : (
            "User Dashboard"
          )
        }
        visible={visible}
        style={{ width: "80vw" }}
        onHide={() => {
          setVisible(false);
          setSelectedMember(null);
        }}
      >
        <div className="row mt-4">
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Today's activity - Working hours</h5>
              <div className="card-body flex justify-content-around">
                <div className="d-flex flex-column align-items-center">
                  <Knob
                    value={workingDailyStepsData}
                    // valueTemplate="{value}"
                    max={4000}
                    size={200}
                    strokeWidth={5}
                    readOnly
                    valueColor="#FB923C"
                    rangeColor="#DEE2E6"
                  />
                  <span className="mt-2" style={{ marginLeft: "75px" }}>
                    {" "}
                    Steps
                  </span>{" "}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <Knob
                    // value={workingDailyTimeData}
                    value={
                      workingDailyTimeData
                        ? parseFloat(workingDailyTimeData).toFixed(2)
                        : "0.00"
                    }
                    // valueTemplate="{value}"
                    max={45}
                    size={200}
                    strokeWidth={5}
                    readOnly
                    valueColor="#7265E2"
                    rangeColor="#DEE2E6"
                  />
                  <span className="mt-2" style={{ marginLeft: "70px" }}>
                    {" "}
                    Minutes
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Today's activity - Non-Working hours </h5>
              <div className="card-body flex justify-content-around">
                <div className="d-flex flex-column align-items-center">
                  <Knob
                    value={nonWorkingDailyStepsData}
                    max={4000}
                    // onChange={(e) => setValue(e.value)}
                    size={200}
                    strokeWidth={5}
                    readOnly
                    valueColor="#FB923C"
                    rangeColor="#DEE2E6"
                  />
                  <span className="mt-2" style={{ marginLeft: "75px" }}>
                    {" "}
                    Steps
                  </span>{" "}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <Knob
                    value={nonWorkingDailyTimeData}
                    max={45}
                    // onChange={(e) => setValue(e.value)}
                    size={200}
                    strokeWidth={5}
                    readOnly
                    valueColor="#7265E2"
                    rangeColor="#DEE2E6"
                  />
                  <span className="mt-2" style={{ marginLeft: "70px" }}>
                    {" "}
                    Minutes
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Weekly activity - Working hours</h5>
              <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={shortenedWeeklyWorkingData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barSize={30}
                  >
                    <XAxis dataKey="Day" stroke="#8884d8" />
                    <YAxis stroke="#8884d8" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Steps" fill="#8676FE" />
                    <Bar dataKey="Time" fill="#FB7748" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Weekly activity - Non-Working hours</h5>
              <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={shortenedWeeklyNonWorkingData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barSize={30}
                  >
                    <XAxis dataKey="Day" stroke="#8884d8" />
                    <YAxis stroke="#8884d8" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Steps" fill="#8676FE" />
                    <Bar dataKey="Time" fill="#FB7748" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Monthly activity - Working hours</h5>
              <div className="card-body flex justify-content-around">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)} // Handle date selection
                  dayClassName={(date) => {
                    const bgColor = getBackgroundColor(date);
                    return bgColor ? `highlight-${bgColor}` : "";
                  }}
                  renderDayContents={(day, date) => highlightWithColors(date)}
                  inline
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <h5 className="card-header">Monthly activity - Non-Working hours </h5>
              <div className="card-body flex justify-content-around">
                <DatePicker
                  selected={selectedDateNW}
                  onChange={(date) => setSelectedDateNW(date)} // Handle date selection
                  dayClassName={(date) => {
                    const bgColor = getBackgroundColorNW(date);
                    return bgColor ? `highlight-${bgColor}` : "";
                  }}
                  renderDayContents={(day, date) => highlightWithColorsNW(date)}
                  inline
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <span className="heading-page mt-0">Members</span>
      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#7265e2",
            }}
          />
        </div>
      )}
      <div className="card mt-2">
        <DataTable
          value={activeMembers}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="slno" header="Sl.No" />
          <Column
            field="fld_name"
            header="Name"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="fld_email"
            header="Email"
            style={{ width: "25%" }}
          ></Column>
          {/* <Column
            field="fld_contact_number"
            header="Contact Number"
            style={{ width: "25%" }}
          ></Column> */}
          <Column
            field="fld_gender_name"
            header="Gender"
            style={{ width: "15%" }}
          ></Column>
          <Column
            field="fld_date_of_birth"
            header="DOB"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="fld_height"
            header="Height"
            style={{ width: "10%" }}
          ></Column>
          <Column
            body={viewButtonTemplate}
            header="Actions"
            style={{ width: "15%" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}
